.root {
    position: sticky;
    top: 0rem;
    z-index: 1;
    background: #fff;
}

.header {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    width: 100.5%;
}

.logo {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 60px;
}

.selected {
    font-size: 1rem;
    border-bottom: 1px solid;
    color: grey;
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

.selectedTransparent {
    font-size: 1rem;
    transition: all 0.15s ease-in-out;
    border-bottom: 1px solid transparent;
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

.selectedTransparent:hover {
    border-bottom: 1px solid;
    color: grey;
}

.navigationAlign {
    text-align: right;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .navigationAlign {
        text-align: center;
    }
}
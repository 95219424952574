.footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.icon {
    padding-left: 10px;
    padding-right: 10px;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    color: #000;
}

.icon:hover {
    color: grey;
}
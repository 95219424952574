.center {
	margin: auto;
	width: 100%;
	text-align: center;
}

.centerImg {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.imageLoad {
	transition: opacity 400ms ease 0ms;
}

.embed-container {
	padding:56.25% 0 25px 0;
	position: relative;
}
   
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
  }
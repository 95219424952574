.App {
  text-align: center;
}

.container {
  width: 85% !important;
}

.App-link {
  color: #61dafb;
}

.background {
  background-color: #9260f0;
}

.left {
  /* font-family: 'Sriracha', cursive; */
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.navTitle {
  /* font-family: 'Sriracha', cursive; */
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.marginZero {
  margin: 0;
}

@media (max-width: 599px) {
  .left, .right {
    text-align: center;
  }
}

@media (max-width: 840px) {
  .container {
    width: 95% !important;
  }
}